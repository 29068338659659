.woodGallery {
  height: 100%;
  max-width: 55%;
  margin: auto;
  display: grid;
  display: inline-grid;
  grid-template-columns: repeat(16, 1fr);
}
.woodGallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1%;
  margin: auto;
  border-radius: 1rem;
}

.woodGal1 {
  grid-row: 1/2;
  grid-column: 6/10;
}
.woodGal2 {
  grid-row: 1/3;
  grid-column: 13/17;
}
.woodGal3 {
  object-position: 38%;
  grid-row: 2/4;
  grid-column: 1/3;
}
.woodGal4 {
  grid-row: 3/4;
  grid-column: 3/6;
}

.woodGal5 {
  grid-row: 1/2;
  grid-column: 1/3;
}
.woodGal6 {
  grid-row: 2/3;
  grid-column: 9/13;
}
.woodGal7 {
  grid-row: 1/3;
  grid-column: 3/6;
  object-position: 100%;
}
.woodGal8 {
  grid-row: 2/4;
  grid-column: 6/9;
}
.woodGal9 {
  grid-row: 3/4;
  grid-column: 9/17;
}
.woodGal10 {
  grid-row: 1/2;
  grid-column: 10/13;
}
.woodGal11 {
  grid-row: 3/4;
  grid-column: 9/11;
}

.swg1 {
  grid-row: 1/3;
  grid-column: 3/4;
}
.swg2 {
  grid-row: 3/4;
  grid-column: 4/5;
}
.swg3 {
  grid-row: 3/4;
  grid-column: 3/4;
}
.swg4 {
  grid-row: 1/3;
  grid-column: 4/7;
}
.swg5 {
  grid-row: 1/4;
  grid-column: 1/3;
}
.swg6 {
  grid-row: 3/4;
  grid-column: 6/7;
}

.woodCarousel,
.woodCarousel2 {
  width: 45%;
  margin: auto;
}

.woodCarousel ul,
.woodCarousel2 ul {
  margin: 0;
}
.woodCarousel li,
.woodCarousel2 li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
  margin: 0mm;
}

@keyframes wScroll {
  0% {
    transform: translate3d(0, 90%, 0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes wScroll2 {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 35%, 0);
  }
}

.woodCarousel,
.woodCarousel2 {
  text-overflow: ellipsis;
  width: 100%;
}

.woodCarousel {
  height: calc(50rem + 1vh);
  animation: wScroll infinite 12s linear;
}
.woodCarousel2 {
  height: calc(50rem + 1vh);
  animation: wScroll2 infinite 22s linear;
}

@keyframes wScroll {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  100% {
    transform: translate3d(0, -130%, 0);
  }
}
@keyframes wScroll2 {
  0% {
    transform: translate3d(0, -330%, 0);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}
