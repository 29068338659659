.slider {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.img {
  height: 100%;
  width: 100%;
  border-radius: 40px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: -7rem;
  font-size: 5rem;
  color: rgb(53, 53, 117);
  cursor: url("/public/assets/black&colored_cursor.png"), auto;
  user-select: none;
  padding: 0rem;
  margin-right: 0%;
}
.left-arrow {
  position: absolute;
  top: 50%;
  left: -7rem;
  font-size: 5rem;
  color: rgb(53, 53, 117);
  cursor: url("/public/assets/black&colored_cursor.png"), auto;
  user-select: none;
}

.slide {
  visibility: hidden;
  opacity: 0;
  z-index: -10;
}

.slide.active1 {
  visibility: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: all 0.2s ease;
  animation: slide 0.2s ease-in;
  z-index: 0;
}
.slide.active2 {
  visibility: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: all 0.2s ease;
  animation: slide2 0.2s ease-in;
  z-index: 3;
}

@keyframes slide {
  0% {
    transform: translate3d(-120%, 2px, -100px);
  }
  100% {
    transform: translate3d(0);
  }
}
@keyframes slide2 {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 840px) {
  .left-arrow {
    position: absolute;
    left: 1px;
    z-index: 5;
  }
  .right-arrow {
    position: absolute;
    right: 1px;
    z-index: 5;
  }
  .slider {
    width: auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
  .img {
    height: 80%;
    width: auto;
    border-radius: 40px;
  }
}
