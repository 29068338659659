.extGal3 {
  grid-row: 1/6;
  grid-column: 3/6;
  object-position: 0% 40%;
}
.extGal2 {
  grid-row: 1/3;
}
.extGal4 {
  grid-row: 1/3;
}

.extGal1 {
  grid-row: 3/6;
  grid-column: 1/3;
}

.extLow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.ext-main-body {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 66vh;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ext-main-body .gallery {
  height: 100%;
  max-width: 75%;
  margin: auto;
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, 1fr);
}

.ext-main-body .gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1%;
  margin: auto;
  border-radius: 3rem;
}

.extBottom {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  height: auto;
}

.extBottom .p2,
.extBottom2 .p2 {
  margin: 4rem;
}

.extWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: clamp(40rem, 60vw, 100rem);
  background: linear-gradient(rgba(3, 84, 142, 0.922), rgba(4, 212, 77, 0.419));
  border-radius: 4rem;
  padding: 4rem;
  position: relative;
}

.extBottom .extMural img {
  height: calc(33rem + 1vh);
  width: 100%;
  border-radius: 5rem;
  object-fit: cover;
}

.extBottom2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto;
  width: 100vw;
}

.extBottom2 .slideWrapper {
  background: linear-gradient(rgba(3, 84, 142, 0.922), rgba(4, 212, 77, 0.419));
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 100vw;
  height: 100%;
  width: 100vw;
}

.extBottom2 .slideWrapper .slideContainer {
  max-width: 100vw;
  height: auto;
  width: 100vw;
  padding: 0rem;
}

.extBottom2 .slideWrapper .slideContainer figure {
  width: clamp(20rem, 100vw, 75rem);
  max-height: 70%;
}

.extBottom2 .slideWrapper .slideContainer .right-arrow {
  position: absolute;
  top: 50%;
  right: 1%;
  font-size: 5rem;
  color: rgb(53, 53, 117);
  cursor: url("/public/assets/black&colored_cursor.png"), auto;
  user-select: none;
  z-index: 10;
}
.extBottom2 .slideWrapper .slideContainer .left-arrow {
  position: absolute;
  top: 50%;
  left: 1%;
  font-size: 5rem;
  color: rgb(53, 53, 117);
  cursor: url("/public/assets/black&colored_cursor.png"), auto;
  z-index: 10;
}

.extBottom2 .slideWrapper figure figcaption {
  font-size: calc(2rem + 0.5vw);
  max-width: 100vw;
  text-align: center;
}

.extBottom2 .slideWrapper figure:hover {
  background-color: #3f6783;
  color: limegreen;
  border-radius: 30px;
  padding: 1rem;
  padding-bottom: 6rem;
  transition: all 0.5s linear;
  cursor: url(data:image/svg+xml,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22currentColor%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%2024%2024%22%20ariaHidden%3D%22true%22%20height%3D%222.5em%22%20width%3D%222.5em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M10.5%203.75a6.75%206.75%200%20100%2013.5%206.75%206.75%200%20000-13.5zM2.25%2010.5a8.25%208.25%200%201114.59%205.28l4.69%204.69a.75.75%200%2011-1.06%201.06l-4.69-4.69A8.25%208.25%200%20012.25%2010.5zm8.25-3.75a.75.75%200%2001.75.75v2.25h2.25a.75.75%200%20010%201.5h-2.25v2.25a.75.75%200%2001-1.5%200v-2.25H7.5a.75.75%200%20010-1.5h2.25V7.5a.75.75%200%2001.75-.75z%22%20clip-rule%3D%22evenodd%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E),
    auto;
}

.extMural figcaption {
  font-size: x-large;
  padding: 2rem;
  width: 100%;
}

.extWrapper:hover {
  color: rgb(188, 205, 2);
  transition: background 1s ease-in-out;
  background: #3f6783;
}

.extCarousel {
  margin: auto;
}

.extCarousel ul {
  margin: 0;
}
.extCarousel li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  margin: 0mm;
}

.extCarousel {
  animation: extGlide infinite 20s linear;
}

@keyframes extGlide {
  0% {
    transform: translate3d(0, 60%, 0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
}

@media screen and (max-width: 495px) {
  .extBottom2 .slideWrapper .slideContainer {
    max-width: 100vw;
    padding: 0rem;
    /* padding-bottom: 1rem; */
  }
  .extBottom2 .slideWrapper figure:hover {
    border-radius: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .extMural img {
    border-radius: 10px;
  }
  .ext-main-body {
    flex-direction: column;
    height: auto;
  }
  .ext-main-body .extCarousel {
    animation: extGlide infinite 16s linear;
    height: calc(50rem + 1vh);
  }
  .ext-main-body .gallery {
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    background: linear-gradient(rgb(92, 92, 191), green);
    flex-direction: column;
    max-width: 100%;
    height: 50%;
  }
  .ext-main-body .gallery img {
    grid-row: auto;
    grid-column: auto;
  }
  .extWrapper {
    margin-bottom: 10rem;
    width: 100%;
    border: none;
    padding: 1rem;
    border-radius: 10px;
  }

  @keyframes extGlide {
    0% {
      transform: translate3d(0, 100%, 0);
    }
    100% {
      transform: translate3d(0, -230%, 0);
    }
  }
}

@media screen and (max-width: 980px) {
  .extWrapper {
    width: 100%;
  }
  .extBottom .extWrapper {
    display: flex;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1616px) {
  .extBottom2 {
    height: auto;
  }
  .extBottom2 .slideWrapper {
    flex-direction: column;
    height: auto;
    align-items: center;
    overflow: hidden;
  }
  .extBottom2 .slideWrapper figure:hover {
    padding: 1rem;
    padding-bottom: 0rem;
  }
}
