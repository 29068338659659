.bus-main-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 70vh;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: row;
}
.bus-main-body .busGallery {
  height: 100%;
  max-width: 55%;
  margin: auto;
  display: grid;
  display: inline-grid;
  grid-template-columns: repeat(10, 1fr);
}

.busGallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1%;
  margin: auto;
  border-radius: 2rem;
}

.busGal1 {
  grid-column: 4/7;
  grid-row: 3/5;
}
.busGal2 {
  grid-column: 4/7;
  grid-row: 1/2;
}
.busGal3 {
  grid-column: 7/11;
  grid-row: 1/5;
}
.busGal4 {
  grid-column: 1/4;
  grid-row: 3/5;
}
.busGal5 {
  grid-column: 1/7;
  grid-row: 2/3;
}
.busGal6 {
  grid-column: 1/4;
  grid-row: 1/2;
  object-position: right;
}

.busCarousel,
.busCarousel2 {
  width: 45%;
  margin: auto;
}

.busCarousel ul,
.busCarousel2 ul {
  margin: 0;
}
.busCarousel li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
  margin: 0mm;
}

.busCarousel2 li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13rem;
  margin: 0mm;
}

.busCarousel {
  animation: bScroll infinite 15s linear;
}

@keyframes bScroll {
  0% {
    transform: translate3d(0, 70%, 0);
  }
  100% {
    transform: translate3d(0, -100%, 0);
  }
}

.busCarousel2 {
  animation: bScroll2 infinite 20s linear;
}

@keyframes bScroll2 {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 60%, 0);
  }
}

.main-body2 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 70vh;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-body2 .secBusGallery {
  height: 100%;
  max-width: 55%;
  margin: auto;
  display: grid;
  display: inline-grid;
  grid-template-columns: repeat(10, 1fr);
}

.main-body2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 2%;
  border-radius: 3rem;
}

.secBusGal1 {
  grid-column: 7/11;
  grid-row: 3/6;
}
.secBusGal2 {
  grid-column: 8/11;
  grid-row: 1/3;
}
.secBusGal3 {
  grid-column: 4/7;
  grid-row: 3/6;
  object-position: left;
}
.secBusGal4 {
  grid-column: 4 /8;
  grid-row: 1/3;
}
.secBusGal5 {
  grid-column: 1/4;
  grid-row: 1/6;
}

.satisfied {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  overflow: hidden;
  scrollbar-width: none;
  position: relative;
  width: 100%;
  height: auto;
  margin: auto;
}

.satisfied .p {
  display: flex;
  flex-wrap: nowrap;
  justify-content: stretch;
  width: 100%;
}

.sidewaysList {
  overflow: hidden;
  height: 5%;
  position: relative;
  animation: sideScroll 80s linear infinite;
  width: auto;
}

.sidewaysList ul {
  display: grid;
  padding: 2rem;
  margin: 3rem;
  grid-template-columns: repeat(34, 1fr);
  max-width: 100%;
}
.sidewaysList li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  width: 50rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

@keyframes sideScroll {
  0% {
    transform: translate3d(62%, 0, 0);
  }
  100% {
    transform: translate3d(-62%, 0, 0);
  }
}

.sidewaysList2 ul {
  display: grid;
  padding: 2rem;
  margin: 3rem;
  grid-template-columns: repeat(34, 1fr);
  max-width: 100%;
}
.sidewaysList2 li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  width: 50rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.sidewaysList2 {
  overflow: hidden;
  height: 5%;
  position: relative;
  animation: sideScroll2 80s infinite linear;
}

@keyframes sideScroll2 {
  0% {
    transform: translate3d(-62%, 0, 0);
  }
  100% {
    transform: translate3d(62%, 0, 0);
  }
}

@media screen and (max-width: 750px) {
  @keyframes sideScroll {
    0% {
      transform: translate3d(52%, 0, 0);
    }
    100% {
      transform: translate3d(-52%, 0, 0);
    }
  }
  @keyframes sideScroll2 {
    0% {
      transform: translate3d(-53%, 0, 0);
    }
    100% {
      transform: translate3d(53%, 0, 0);
    }
  }

  .busCarousel {
    animation: bScroll infinite 17s linear;
  }
  .busCarousel2 {
    animation: bScroll2 infinite 25s linear;
  }

  .bus-main-body {
    flex-direction: column;
    height: max-content;
  }

  .bus-main-body .busCarousel {
    text-overflow: ellipsis;
    width: 100%;
    height: calc(45rem + 0.5vh);
  }

  .bus-main-body .busGallery {
    background: linear-gradient(rgba(92, 92, 191, 0.5), green);
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
    flex-direction: column;
    max-width: 100%;
  }

  .bus-main-body .carousel {
    text-overflow: ellipsis;
    height: 50%;
    width: 100%;
  }
  .bus-main-body .busGallery img {
    border-radius: 4rem;
    grid-row: auto;
    grid-column: auto;
  }

  @keyframes bScroll {
    0% {
      transform: translate3d(0, 90%, 0);
    }
    100% {
      transform: translate3d(0, -230%, 0);
    }
  }

  .main-body2 {
    justify-content: center;
    flex-direction: column;
    height: max-content;
  }
  .main-body2 .busCarousel2 {
    text-overflow: ellipsis;
    width: 100%;
    height: calc(55rem + 0.5vh);
  }

  @keyframes bScroll2 {
    0% {
      transform: translate3d(0, -250%, 0);
    }
    100% {
      transform: translate3d(0, 100%, 0);
    }
  }

  .main-body2 .secBusGallery {
    background: linear-gradient(rgba(92, 92, 191, 0.5), green);
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
    flex-direction: column;
    max-width: 100%;
  }
  .main-body2 .secBusGallery img {
    border-radius: 4rem;
    grid-row: auto;
    grid-column: auto;
  }

  .busGallery {
    background: linear-gradient(rgb(92, 92, 191), green);
    flex-direction: row;
    max-width: 100%;
    height: 50%;
  }
}
