.bedGallery {
  height: auto;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, clamp(25rem, 100vw, 80rem));
  grid-template-rows: max(70vh);
  background: linear-gradient(rgba(92, 92, 191, 0.3), green);
  padding: 1rem;
  max-width: 100%;
  overflow: hidden;
}

.bedGallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10%;
  padding: 2rem;
}

.bedBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-align: center;
}

.bedBottom ul {
  display: grid;
  margin: 1rem;
  grid-template-columns: repeat(3, 1fr);
  max-width: 100vw;
}
.bedBottom ul li {
  display: inline-flex;
  align-items: center;
  justify-self: center;
  padding: 2rem 1rem;
}

.bedBottom ul li:hover,
.mural:hover figcaption {
  background-color: #3f6783;
  border-radius: 30px;
  transition: all 0.4s linear;
  color: rgb(188, 205, 2);
}

@media screen and (max-width: 600px) {
  .bedGallery {
    display: inline-flex;
    justify-content: space-between;
    height: auto;
    flex-direction: column;
  }
  .bedBottom ul {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 1385px) {
  .bedGallery div {
    border-radius: 2rem;
    padding: 0.4rem;
  }
}
