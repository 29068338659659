.kDisplay {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 50vh;
  position: relative;
  overflow: hidden;
}

.kDisplay .kCarousel {
  width: 45%;
  margin: auto;
}

.kCarousel ul {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.kCarousel li {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 12rem;
  width: 100%;
}

.kGallery {
  height: 80vh;
  padding: 1rem 0rem 2rem 0rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(rgba(92, 92, 191, 0.8), green);
}
.kGallery .kIntContainer {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: center;
  max-width: 100vw;
  width: clamp(30rem, 100vw, 65rem);
}

.kGallery .kIntContainer figure {
  height: 100%;
  padding-bottom: 20%;
}

.kGallery figcaption {
  width: 100%;
  text-align: center;
  font-size: calc(2rem + 0.4vw);
}

.kGallery .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6rem;
  padding: 2rem;
}

.kSidewaysList {
  overflow: hidden;
  height: 5%;
  position: relative;
  animation: kSideScroll 38s linear infinite;
  width: auto;
}

.kSidewaysList ul {
  display: grid;
  padding: 2rem;
  margin: 3rem;
  grid-template-columns: repeat(34, 1fr);
  max-width: 100%;
}
.kSidewaysList li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50rem;
  margin: 0mm;
}

.kGallery .kIntContainer:hover {
  border-radius: 30px;
  padding: 1rem;
  font-size: x-large;
  color: limegreen;
  background-color: #3f6783;
  transition: all 0.5s ease-in;
}

@keyframes kSideScroll {
  0% {
    transform: translate3d(66%, 0, 0);
  }
  100% {
    transform: translate3d(-66%, 0, 0);
  }
}

.kDisplay .kCarousel {
  height: calc(10rem + 1vwh);
}

.kCarousel ul {
  animation: kSlide infinite 20s linear 0s;
}

.kDisplay {
  flex-direction: column;
  max-width: 100%;
  padding: 0rem;
  margin: 0rem;
}

.kDisplay .kCarousel {
  width: 70%;
}

.kDisplay .kGallery img {
  border-radius: 2rem;
}

@keyframes kSlide {
  0% {
    transform: translate3d(0, 60%, 0);
  }
  100% {
    transform: translate3d(0, -60%, 0);
  }
}

@keyframes kSideScroll {
  0% {
    transform: translate3d(49%, 0, 0);
  }
  100% {
    transform: translate3d(-53%, 0, 0);
  }
}

@media screen and (max-width: 850px) {
  .kGallery .kIntContainer .slider figure {
    width: 100%;
  }
  .kGallery {
    height: auto;
  }
}

@media screen and (max-width: 1000px) {
  .kGallery figure .img {
    width: 100%;
  }
}

@media screen and (max-width: 1555px) {
  .kDisplay {
    justify-content: center;
    align-items: baseline;
  }
}
