.signGallery {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 20vh);
  background: linear-gradient(rgba(92, 92, 191, 0.3), green);
  padding: 1rem;
  width: 100%;
  height: 82vh;
}

.signGallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3rem;
  padding: 0.8rem;
}

.signGallery div:nth-child(1) {
  grid-row: 3/5;
  grid-column: 1/4;
}

.signGallery div:nth-child(2) {
  grid-row: 2/3;
  grid-column: 1/4;
}
.signGallery div:nth-child(3) {
  grid-row: 1/2;
  grid-column: 1/4;
}
.signGallery div:nth-child(4) {
  grid-row: 3/5;
  grid-column: 4/6;
}
.signGallery div:nth-child(5) {
  grid-row: 3/5;
  grid-column: 6/8;
}
.signGallery div:nth-child(6) {
  grid-row: 4/5;
  grid-column: 8/9;
}
.signGallery div:nth-child(7) {
  grid-row: 2/5;
  grid-column: 9/11;
}
.signGallery div:nth-child(8) {
  grid-row: 3/4;
  grid-column: 8/9;
}
.signGallery div:nth-child(9) {
  grid-row: 1/2;
  grid-column: 10/11;
}
.signGallery div:nth-child(10) {
  grid-row: 2/3;
  grid-column: 7/9;
}
.signGallery div:nth-child(11) {
  grid-row: 1/2;
  grid-column: 8/10;
}
.signGallery div:nth-child(12) {
  grid-row: 1/3;
  grid-column: 4/6;
}
.signGallery div:nth-child(13) {
  grid-row: 1/2;
  grid-column: 6/8;
}
.signGallery div:nth-child(14) {
  grid-row: 2/3;
  grid-column: 6/7;
}

@media screen and (max-width: 892px) {
  .signGallery {
    grid-template-columns: repeat(auto-fit, minmax(min(26rem, 100%), 1fr));
    grid-template-rows: repeat(auto-fit, minmax(min(20rem, 100%), 1fr));
    width: 100%;
    height: auto;
  }

  .signGallery div:nth-child(1) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(2) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(3) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(4) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(5) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(6) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(7) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(8) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(9) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(10) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(11) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(12) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(13) {
    grid-row: auto;
    grid-column: auto;
  }
  .signGallery div:nth-child(14) {
    grid-row: auto;
    grid-column: auto;
  }
}
