.main-head {
  background: linear-gradient(rgb(92, 92, 191), green);
  position: sticky;
  top: 0;
  z-index: 11;
}

.contactButton {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

#logo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  /* flex: 5 4 50rem; */
  font-size: calc(3rem + 0.5vw);
  color: limegreen;
  text-shadow: 0.5px 0.5px 1px rgb(92, 92, 191);
  font-family: "Parisienne", cursive;
  padding: 1.5rem;
}
#logo h5 {
  flex: 5 4 50rem;
  font-size: calc(1.5rem + 0.5vw);
  text-shadow: 0.5px 0.5px 1px rgb(92, 92, 191);
}
#logo h6 {
  display: flex;
  flex-direction: left;
}

#logo h4 {
  justify-self: center;
}

#logo div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

nav {
  display: flex;
  width: 95%;
  max-width: 100vw;
  min-height: 5vh;
  padding: 1rem;
  margin: auto;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 700;
}

/* nav (children) */
nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex: 1 1 80rem;
  position: relative;
}

nav li {
  display: flex;
  justify-content: center;
  padding: 0.3rem;
  position: relative;
}

nav li a,
.commercial,
.residential,
.contact {
  text-align: center;
  text-decoration: none;
  color: rgb(78, 78, 137);
  text-shadow: 8px 4px rgba(0, 0, 0, 0.1), 0px 20px 20px rgba(0, 0, 0, 0.1);
  font-size: calc(2.4rem + 0.45vw);
  position: relative;
  padding: 1rem;
}

.commercial,
.residential,
.contact {
  cursor: pointer;
  position: relative;
}

nav .com-menu .holder {
  display: flex;
  flex-direction: column;
  visibility: hidden;
  position: absolute;
  background-color: #3f6783;
  border-radius: 0% 0% 1rem 1rem;
  max-width: 100vw;
  width: 100%;
}

nav .com-wrapper .com-menu .holder,
.contact-com-wrapper {
  transition-delay: 0s, 0s, 0.3s;
}

nav .com-wrapper:hover .com-menu .holder {
  visibility: visible;
  z-index: 3;
  margin-bottom: 0px;
  transform: translateY(0%);
  transition: all 1s;
}

nav .com-menu ul {
  position: relative;
}

nav .com-menu ul li a {
  color: rgb(63, 63, 115);
  font-size: calc(2.3rem + 0.45vw);
  text-shadow: 6px 2px rgba(0, 0, 0, 0.1), 0px 20px 20px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  padding: 0.3rem;
}

nav li a:hover,
.commercial:hover,
.residential:hover,
.contact:hover {
  cursor: url("/public/assets/black&colored_cursor.png"), auto;
  animation: hoverAnimation 0.01s ease forwards 0.08s;
}

.contact-com-wrapper:hover {
  transition: all 3s;
  animation: spin 1s infinite;
}

.com-wrapper:hover span {
  z-index: 3;
}

.contact2 {
  transition: all 3s;
  text-align: center;
  text-decoration: none;
  text-shadow: 8px 8px rgba(0, 0, 0, 0.1), 0px 20px 20px rgba(0, 0, 0, 0.1);
  font-size: calc(2.8rem + 0.55vw);
  animation: hoverAnimation 0.3s ease forwards 0.18s;
}

@keyframes hoverAnimation {
  0% {
    color: rgb(99, 35, 119);
  }
  25% {
    color: rgb(35, 98, 119);
  }
  100% {
    color: limegreen;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

/* home interactive slider */
.interactive {
  background: linear-gradient(rgba(92, 92, 191, 0.3), green);
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.interactive figure figcaption {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(2rem + 0.4vw);
}
.interactive figure:hover {
  border-radius: 30px;
  background-color: #3f6783;
  color: limegreen;
  padding: 0.6rem 1rem;
  font-size: calc(1rem + 0.7vw);
  transition: all 0.4s ease;
}

.interactive .slideContainer {
  height: 60rem;
  width: clamp(40rem, 100vw, 65rem);
}

.interactive .slideContainer img {
  height: 45rem;
  width: fit-content;
}

.interactive .slideContainer figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* sliding-gallery display */
.navRight {
  opacity: 0.8;
  position: absolute;
  right: 0.1vw;
  top: calc(40rem + 10vh);
  font-size: 10rem;
  color: rgb(53, 53, 117);
  cursor: url("/public/assets/black&colored_cursor.png"), auto;
  user-select: none;
  z-index: 10;
}

.navLeft {
  opacity: 0.8;
  position: absolute;
  top: calc(40rem + 10vh);
  left: -1rem;
  font-size: 10rem;
  color: rgb(53, 53, 117);
  cursor: url("/public/assets/black&colored_cursor.png"), auto;
  user-select: none;
  z-index: 10;
}

.navRight:hover path,
.navLeft:hover path {
  color: limegreen;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.sliding-gallery {
  min-height: 63vh;
  height: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 100vw;
}

.sliding-gallery::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.sliding-gallery ul {
  display: flex;
  padding: 0em 0em;
  position: absolute;
  height: 100%;
  max-width: 100vw;
  width: auto;
  scrollbar-track-color: transparent;
  scrollbar-width: none;
  scroll-behavior: smooth;
  padding: 1rem 0rem;
  z-index: 3;
}

.sliding-gallery li,
.sliding-gallery li div {
  height: 100%;
  width: clamp(45rem, 40vw, 60rem);
  transition: all 0.4s ease-in-out;
  padding: 0.5rem 1rem;
}

.sliding-gallery li div img {
  object-fit: cover;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  transition: all ease-in-out 0.4s;
}
.sliding-gallery li:hover,
.sliding-gallery li:hover div,
.sliding-gallery li:hover div img {
  border-radius: 2rem;
  cursor: url(data:image/svg+xml,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22currentColor%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%2024%2024%22%20ariaHidden%3D%22true%22%20height%3D%223em%22%20width%3D%223em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M10.5%203.75a6.75%206.75%200%20100%2013.5%206.75%206.75%200%20000-13.5zM2.25%2010.5a8.25%208.25%200%201114.59%205.28l4.69%204.69a.75.75%200%2011-1.06%201.06l-4.69-4.69A8.25%208.25%200%20012.25%2010.5zm8.25-3.75a.75.75%200%2001.75.75v2.25h2.25a.75.75%200%20010%201.5h-2.25v2.25a.75.75%200%2001-1.5%200v-2.25H7.5a.75.75%200%20010-1.5h2.25V7.5a.75.75%200%2001.75-.75z%22%20clip-rule%3D%22evenodd%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E),
    auto;
  z-index: 9;
  padding: auto;
  transform: scale(1.03, 1.03);
}

#subtitle {
  display: flex;
  font-family: "Parisienne", cursive;
  font-size: calc(5rem + 2vw);
  justify-content: center;
  text-align: center;
  background-image: linear-gradient(180deg, rgb(53, 53, 117), limegreen);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 0.5px;
}

/* color pallet display */
.colors {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 300vh;
  padding: 2rem;
}

.note {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 120%;
  height: 0%;
}
.note p {
  color: yellow;
  font-size: calc(2rem + 0.5vh);
  font-weight: 900;
  text-shadow: 1px 1px 2px #3f6783;
}

.arrow {
  font-size: calc(5rem);
  transition: all 3s;
  animation: spin 1s infinite;
}

@keyframes twister {
  0% {
    transform: rotateX(30deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}

.pallet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.pallet ul {
  display: grid;
  grid-template-columns: repeat(6, 16vw);
  scroll-behavior: smooth;
}

.pallet li {
  display: flex;
  align-items: center;
}

.pallet img:hover {
  border-radius: 50% 26% 50% 42%;
  background-color: #3f6783;
  cursor: url("/public/assets/black&colored_cursor.png"), auto;
}

.pallet img {
  width: 100%;
  height: 100%;
  max-width: 20rem;
  margin: auto;
  padding: 0.7rem;
  object-fit: contain;
}

.invert img {
  background: white;
  border-radius: 50%;
}

.sub-headers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: auto;
  margin: auto;
  padding: 4rem 1rem 0rem 1rem;
}

.main-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 70vh;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carousel {
  width: 45%;
}

.carousel ul {
  margin: 0;
}
.carousel li {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
}

.main-body .carousel {
  animation: autoSlide infinite 30s linear 0s;
}

@keyframes autoSlide {
  0% {
    transform: translate3d(0, 64%, 0);
  }
  100% {
    transform: translate3d(0, -64%, 0);
  }
}

.main-body .gallery {
  height: 100%;
  max-width: 100vw;
  width: 65%;
  margin: auto;
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, 1fr);
}

.main-body .gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1%;
  margin: auto;
  border-radius: 1rem;
}

.homeGal1 {
  grid-column: 1/4;
  object-position: 40% 60%;
}
.homeGal2 {
  grid-column: 2/4;
  grid-row: 2/3;
  object-position: center;
}

.homeGal3 {
  grid-column: 1/2;
  grid-row: 2/3;
  object-position: center;
}

.homeGal4 {
  grid-column: 4/6;
  grid-row: 1/3;
  object-position: 85%;
}

.bottom {
  padding: 1rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bottom ul {
  display: grid;
  padding: 2rem;
  margin: 1rem;
  grid-template-columns: repeat(3, 1fr);
  max-width: 100vw;
}
.bottom ul li {
  display: inline-flex;
  align-items: center;
  justify-self: center;
  padding: 1rem;
  font-weight: 600;
  font-size: calc(1.1rem + 0.7vw);
}
.bottom ul li h4 {
  font-size: calc(1.8rem + 0.9vw);
}

.bottom ul li {
  border-radius: 0rem;
}

.bottom ul li:hover {
  background-color: #3f6783;
  color: rgb(188, 205, 2);
  border-radius: 2rem;
  transition: color ease-in-out 1s;
  transition: all 0.4s linear;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: calc(78rem + 1.8vw);
  height: calc(85rem + 1vh);
  border: rgba(53, 53, 117, 0.5) 10px solid;
  padding-bottom: 20%;
  border-radius: 7%;
}

.mural {
  aspect-ratio: 18/15;
  margin-top: 5rem;
  padding: 2rem 3rem;
  margin: 3rem;
}

.mural img {
  width: 100%;
  height: 100%;
  border-radius: 5%;
  object-fit: cover;
}

.mural h5 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: calc(1rem + 1vw);
  padding: 0.5rem;
  border-radius: 0%;
}

.bottom .wrapper:hover {
  transition: all 2s ease-in-out;
  background: #3f6783;
  border-radius: 20px;
}

.closer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.p3 {
  padding: 3rem;
}

footer {
  background: linear-gradient(rgba(3, 84, 142, 0.922), rgba(4, 212, 77, 0.419));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;
}

footer .copyright {
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

footer .copyright h3 {
  font-family: Suranna;
  font-size: calc(17px + 0.11vw);
}

footer img {
  justify-content: center;
  height: 20vh;
  object-fit: cover;
}

footer .estimate,
footer .risk-free {
  overflow: hidden;
}

footer .risk-free img,
footer .estimate img {
  object-fit: cover;
  width: calc(7rem + 3vw);
  height: calc(7rem + 3vw);
}

@media screen and (max-width: 440px) {
  nav {
    padding: 0%;
  }
  nav ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 0rem;
    padding: 0rem;
  }
  nav li a,
  .commercial,
  .residential,
  .contact {
    font-size: calc(2rem + 0.96vw);
  }
  nav .com-menu ul {
    margin: auto;
    padding: auto;
  }
  nav .com-menu ul li {
    font-size: calc(2.95rem + 0.33vw);
    padding: 0.1rem;
  }
}

@media screen and (max-width: 500px) {
  .bottom p {
    margin: 1rem;
  }
  .bottom ul {
    grid-template-columns: repeat(1, 1fr);
  }
  .bottom li {
    margin: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .closer {
    margin-top: 2rem;
  }
  .main-body .gallery {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    height: 50%;
  }
  .main-body .gallery img {
    grid-row: auto;
    grid-column: auto;
  }
}

@media screen and (max-width: 660px) {
  .main-head {
    position: relative;
  }
  .colors::after {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 1rem;
    content: "Press color icons to change backgrounds!";
    font-size: calc(1.5em + 2vw);
    color: limegreen;
    text-shadow: 2px 2px 2px #3f6783;
  }

  .bottom ul {
    padding: 2rem;
    margin: 1rem;
    grid-template-columns: repeat(auto-fit, 1fr);
    max-width: 100vw;
  }
}

@media screen and (max-width: 687px) {
  .bottom ul {
    max-width: 100vw;
    padding: 0.05rem;
  }
  .bottom ul li h4 {
    font-size: calc(1.7rem + 00.81vw);
  }

  .wrapper {
    margin: 0rem;
  }
  .bottom {
    padding: 0.1rem;
  }
}

@media screen and (max-height: 700px) {
  .colors::after {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 1rem;
    content: "Press color icons to change backgrounds!";
    font-size: calc(1.5em + 2vw);
    color: limegreen;
    text-shadow: 2px 2px 2px #3f6783;
  }
  .main-head {
    position: relative;
  }
  .contactButton {
    color: black;
    text-decoration: none;
    cursor: auto;
  }
}

@media screen and (max-width: 730px) {
  nav ul {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  nav li .com-wrapper span {
    padding: 0.5rem;
  }
  .pallet ul {
    grid-template-columns: repeat(3, 30vw);
  }
}

@media screen and (max-width: 780px) {

  .interactive figure {
    height: fit-content;
    width: 100%;
  }

  .interactive .slideContainer img {
    padding: 0rem;
    height: fit-content;
    width: 80%;
  }
  .contact {
    padding: 0rem;
  }
  .wrapper,
  .mural {
    height: auto;
    width: 100vw;
    padding: 0rem;
    border: none;
  }

  footer .estimate,
  footer .risk-free {
    display: none;
  }

  footer .copyright img {
    height: 15vh;
  }
}
@media screen and (max-width: 900px) {
  
  .main-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0%;
    height: auto;
    width: 100vw;
  }
  .main-body .carousel {
    animation: autoSlide infinite 20s linear 0s;
    text-overflow: ellipsis;
    width: 100%;
    height: calc(50rem + 1vh);
  }
  .main-body .gallery {
    width: 100vw;
    background: linear-gradient(rgb(92, 92, 191), green);
    padding: 0rem;
    margin: 0rem;
  }

  .main-body .gallery img {
    padding: 1rem;
    border-radius: 5rem;
  }

  @keyframes autoSlide {
    0% {
      transform: translate3d(0, 100%, 0);
    }
    100% {
      transform: translate3d(0, -410%, 0);
    }
  }
}

@media screen and (max-width: 1100px) {
  .note p {
    display: none;
  }
  /* .colors::after {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 1rem;
    content: "Press color icons to change backgrounds!";
    font-size: calc(1.5em + 2vw);
    color: limegreen;
    text-shadow: 2px 2px 2px #3f6783;
  } */
}

@media screen and (max-width: 1293px) {
  #logo {
    justify-content: center;
    align-items: center;

    padding: 1.5rem;
    margin-right: 0rem;
  }
  #logo h4 {
    padding: 0%;
  }

  #logo {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  nav li {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

