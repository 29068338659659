.bathGallery {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(auto-fit, max(35em));
  background: linear-gradient(rgba(92, 92, 191, 0.3), green);
  max-width: 100%;
}

.bathGallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3rem;
  padding: 0.8rem;
}

.bathGallery div:nth-child(1) {
  grid-row: 1/2;
  grid-column: 3/5;
}
.bathGallery div:nth-child(2) {
  grid-row: 1/3;
  grid-column: 1/2;
}

.bathGallery div:nth-child(3) {
  grid-row: 1/2;
  grid-column: 2/3;
}
.bathGallery div:nth-child(4) {
  grid-row: 2/3;
  grid-column: 4/6;
}
.bathGallery div:nth-child(5) {
  grid-row: 2/3;
  grid-column: 2/4;
}
.bathGallery div:nth-child(7) {
  grid-row: 2/3;
  grid-column: 1/2;
}

.bathBottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  text-align: center;
}

.bathBottom ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.bathBottom ul li {
  display: inline-flex;
  align-items: center;
  justify-self: center;
  padding: 2rem;
  font-weight: 600;
  font-size: calc(1.1rem + 0.7vw);
  max-width: fit-content;
}

.bathBottom ul li:hover {
  background-color: #3f6783;
  border-radius: 50px;
  transition: all 1s linear;
  color: rgb(188, 205, 2);
  transition: all linear 0.7s;
}

@media screen and (max-width: 520px) {
  .bathGallery {
    display: inline-flex;
    justify-content: space-between;
    height: auto;
    flex-direction: column;
  }
}

@media screen and (max-width: 1280px) {
  .bathGallery {
    grid-template-columns: repeat(auto-fit, min(clamp(20rem, 100vw, 45rem)));
  }

  .bathGallery div:nth-child(1) {
    grid-row: auto;
    grid-column: auto;
  }
  .bathGallery div:nth-child(2) {
    grid-row: auto;
    grid-column: auto;
  }
  .bathGallery div:nth-child(3) {
    grid-row: auto;
    grid-column: auto;
  }
  .bathGallery div:nth-child(4) {
    grid-row: auto;
    grid-column: auto;
  }
  .bathGallery div:nth-child(5) {
    grid-row: auto;
    grid-column: auto;
  }
  .bathGallery div:nth-child(6) {
    grid-row: auto;
    grid-column: auto;
  }
  .bathGallery div:nth-child(7) {
    grid-row: auto;
    grid-column: auto;
  }
  .bathBottom {
    flex-direction: column;
  }
  .bathBottom ul {
    grid-template-columns: repeat(1, 1fr);
  }
}
