.modal-backdrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: all 2s linear;
}

.modal {
  color: wheat;
  padding: 10px 10px 20px 10px;
  max-width: 480px;
  margin: 200px auto;
  background: linear-gradient(rgba(3, 84, 142, 1), rgb(5, 152, 56));
  border-radius: 10px;
  z-index: 11;
}
.modal p {
  font-family: "Roboto", sans-serif;
}
.modal h4 {
  font-family: Monoton, sans-serif;
}

.modal div {
  display: flex;
  justify-content: flex-end;
  z-index: 10;
}

.x {
  background: transparent;
  position: relative;
  border: 2px solid wheat;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  padding: 2px 9px 4px 9px;
  font-size: 2em;
  color: wheat;
  z-index: 10;
}
